<template>
  <div>
    <van-swipe :autoplay="3000"
      @change="onChange">
      <van-swipe-item v-for="item in imgsList"
        :key="item.ImgUrls+'11'">
        <!-- <video v-if="item.type == 'mp4'"
          style="width: 100%"
          :src="item.Video"
          :poster="item.ImgUrls"
          controls="controls">
          您的浏览器不支持 video 标签。
        </video> -->
        <img v-if="item.type == 'img'"
          :src="item.ImgUrls"
          style="width:100%;height:290px;" />
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator"> <span style="font-size:16px;">{{ count + 1}}</span>/{{imgsList.length}}</div>
      </template>
    </van-swipe>
    <div style="border-bottom: 10px solid #f8f8f8;padding:20px 0;">
      <div style="width:95%;margin:auto;">
        <div class="biaoti">
          <span class="title">{{details.Name}}</span>
          <span class="money">{{details.OrgName}}
            <!-- <span style="font-size: 16px;">起</span>  -->
          </span>
        </div>
        <div style="color:#31D984;margin:5px 0 10px;">
          <span style="padding: 5px 10px;background: #31D98405;">经营范围：{{details.Range}}</span>
        </div>
        <div class="time">
          <van-cell icon="clock"
            style="padding:0;color:#c8cad8;"
            color="#c8cad8">
            <!-- <template #right-icon>
              <van-icon name="arrow"
                color="#999999"
                style="line-height: 30px;" />
            </template> -->
            <template #title>
              <span class="custom-title time"
                style="font-size:16px;text">&nbsp;&nbsp;{{details.BusinessTime}}</span>
            </template>
          </van-cell>
        </div>
        <div class="time">
          <van-cell icon="phone"
            style="padding:0;color:#c8cad8;"
            color="#c8cad8">
            <template #right-icon>
              <van-icon name="arrow"
                color="#999999"
                style="line-height: 30px;" />
            </template>
            <template #title>
              <span class="custom-title time"
                style="font-size:16px;text">&nbsp;&nbsp;<a :href="'tel:' + details.TouchTel"
                  style="color:black;">{{details.TouchTel}}</a></span>
            </template>
          </van-cell>

        </div>
        <div class="time"
          @click="gotominiProgram(details)"
          style="font-size:16px;">
          <van-cell icon="location"
            style="padding:0;color:#c8cad8;"
            color="#c8cad8">
            <template #right-icon>

              <van-icon name="arrow"
                color="#999999"
                style="line-height: 30px;padding-left:20px;" />
            </template>
            <template #title>
              <span class="custom-title time"
                style="font-size:16px;">&nbsp;&nbsp;{{details.Address}}</span>
            </template>
          </van-cell>

        </div>
      </div>
    </div>
    <div style="width:95%;margin:auto;">
      <div style="margin-bottom:20px;">
        <div class="zhuyao">—&nbsp;&nbsp;猜你喜欢&nbsp;&nbsp;—</div>
        <!-- <div class="zhuyaoJd">
          主要景点
          <span @click="gotoUrlZy('/travel/jingqu/jqXiangQIng/jdMore/'+ details.SSId)">更多</span>
        </div> -->
        <div class="waterfall">
          <div class="list"
            v-for="item in landScape"
            :key="item.LSId"
            v-show="item.EateryId != details.EateryId"
            @click="gotoUrlZy('/travel/deliciousFood/foodXq/'+ item.EateryId)">
            <img v-if="item.Imgs == ''"
              src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
            <img v-else
              :src="url+ item.Imgs.split(',')[0]" />
            <!-- <img :src="item.ThematicUrl"
            alt=""> -->
            <div style="width:90%;margin:auto;">
              <div class="address">{{item.Name}}</div>
              <div class="count van-multi-ellipsis--l3">{{item.Address}}</div>
              <br>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { WeGetEateryByEateryId, WeGetEateryPage } from "@/api/jingqu";
import config from "@/config"
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data () {
    return {
      details: {},
      count: '',
      imgsList: [],
      list: [
        { RIId: '1', Title: '洛阳水席>牡丹燕菜', address: '洛阳·栾川县', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/fda33f6c-26d.png', count: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '20' },
        { RIId: '2', Title: '洛阳不翻汤', address: '洛阳·嵩县', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/99ebd0d2-b08.png', count: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '38' },
        { RIId: '2', Title: '洛阳不翻汤', address: '洛阳市', ThematicUrl: 'https://xczxapi.zhsq.cloud//upload/images/2022/5/99ebd0d2-b08.png', count: '洛阳水席，是河南洛阳一带特色传统名宴，属于豫菜系。洛阳水席', money: '38' },
      ],
      strategy: {},
      landScape: [],
      url: config.hostUrl
    }
  },
  created () {
    this.getEateryByEateryId()
    // this.getStrategyBySTfId()
    this.getEateryPage()
  },
  methods: {
    onChange (index) {
      this.count = index;
    },
    getEateryByEateryId () {
      console.log(this.$route.params.Id);
      WeGetEateryByEateryId({ EateryId: this.$route.params.Id }).then((res) => {
        this.details = res.data.data
        var ImgsList = []
        var imgsList = res.data.data.ImgUrls.split(",")
        // if (res.data.data.Video != '') {
        //   this.imgsList.push({ ImgUrls: '', type: 'mp4', Video: res.data.data.Video })
        // }
        for (let index = 0; index < imgsList.length; index++) {
          const img = imgsList[index];
          this.imgsList.push({ ImgUrls: img, type: 'img', Video: '' })
        }



      })
    },

    // 特色美食-猜你喜欢
    getEateryPage () {
      WeGetEateryPage({ page: 1, limit: 4 }).then((res) => {
        this.landScape = res.data.data
      })
    },

    // 景区介绍
    gotoUrl (path) {
      this.$router.push(path);
    },
    // 主要景点
    gotoUrlZy (path) {
      this.$router.push(path);
    },
    // 跳转小程序导航
    gotominiProgram (details) {
      console.log(details);
      if (details.Coordinate == '') {
        Toast.fail('暂无地理位置');
      } else {
        this.nameCc = {
          Navigation: '导航',
          Coordinate: details.Coordinate
        }
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../public/openminip?name=" + JSON.stringify(this.nameCc),
          });
        } else {
          alert("请在小程序中打开");
        }
      }

    },
  }
}
</script>

<style scoped>
.custom-indicator {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 2px 5px;
  font-size: 14px;
  color: #ffffff;
  /* background: rgba(255, 255, 255, 0.788); */
}
.biaoti {
  display: flex;
  justify-content: space-between;
}
.title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 550;
}
.money {
  line-height: 33px;
  font-family: PingFang SC;
  /* font-weight: 550; */
  color: #999999;
  font-size: 16px;
}
.time {
  /* display: flex;
  justify-content: space-between; */
  padding: 5px 0;
  color: #0c0c0c;
  font-weight: bold;
  line-height: 30px;
}
.zhuyao {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: center;
}
.zhuyaoJd {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.zhuyaoJd span {
  color: rgb(153, 153, 153);
  float: right;
  font-size: 12pt;
  font-weight: 300;
}
.waterfall {
  /* width: 95%; */
  margin: 10px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 16px;
}
</style>